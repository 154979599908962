import React from 'react'

export default function (props) {
  const {tick, transferable, available, balance} = props;
  return (
    <div className = "flex flex-col gap-1 bg-gray-900 border-solid border-gray-700 rounded-md border-[1px] p-2 w-full">
      <div className="font-semibold text-primary">{tick}</div>
      <div className="flex flex-row justify-between">
        <div>Transferable</div>
        <div>{transferable}</div>
      </div>
      <div className="flex flex-row justify-between border-b-gray-700 border-b-soild border-b-[1px] pb-1">
        <div>Available</div>
        <div>{available}</div>
      </div>
      <div className="flex flex-row justify-between">
        <div>Balance</div>
        <div>{balance}</div>
      </div>
    </div>
  )
}
