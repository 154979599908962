import React, { useEffect , useState} from 'react'
import { getInscriptionContent } from '../util/api';
import Natdogs from './Unat/Natdogs';

export default function UnatCard({data}) {
  const {inscriptionId, contentId, dep, tick, blk} = data;
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(contentId);
        if (contentId != "" && contentId != null) {
          let inscriptionHtml =  await getInscriptionContent(contentId);
          let content = inscriptionHtml.replaceAll('/content/','https://ordinals.com/content/');
          content += '<style> html{height:100%;}</style>'
          content = content.replace('MINT_INSCRIPTION_ID', inscriptionId);
          setHtmlContent(content);
        }
      }
      catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, [])

  return (
    <div className="flex flex-col gap-1 bg-gray-900 border-solid border-gray-700 rounded-md border-[1px] p-2 w-full">
      <span>{tick} {blk}</span>
      {
        contentId != "" ?
          <iframe srcDoc={htmlContent} scrolling="no" sandbox="allow-scripts" loading="lazy" className="w-full min-h-[256px]"></iframe> : <></>
          
      }
      {
        contentId == "" && tick != 'natfishes' && tick != 'natdogs' ? <iframe src={`https://ordinals.com/preview/${inscriptionId}`} scrolling="no" sandbox="allow-scripts" loading="lazy" className="w-full min-h-[256px] rounded-md" ></iframe> :<></>
      }
      {
        contentId == "" && tick == 'natfishes' ? <img src={`https://cdn.arrival.so/natfish_images/image_${blk}.png`}  className="w-full min-h-[256px] rounded-md" />:<></>
      }
      {
        contentId == "" && tick == 'natdogs' ? <Natdogs blockNumber = {blk} />:<></>
      }
    </div>
  )
}
