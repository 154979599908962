import React, {useState, useEffect} from 'react'
import { v4 as uuidv4 } from 'uuid';

import BackgroundImg from "../../assets/imgs-natdogs/Background/Background.png";
import MouthImg from "../../assets/imgs-natdogs/Core Trait Mouth/Mouth.png";

import Confused1stImg from "../../assets/imgs-natdogs/Core Traits Eyes/Confused 1st.png";
import Left3rdImg from "../../assets/imgs-natdogs/Core Traits Eyes/Left 3rd.png";
import Right2ndImg from "../../assets/imgs-natdogs/Core Traits Eyes/Right 2nd.png";

import d1_0Img from "../../assets/imgs-natdogs/Digit 1 Value Head/Dark Grey d1_0.png";
import d1_1Img from "../../assets/imgs-natdogs/Digit 1 Value Head/Purple d1_1.png";
import d1_2Img from "../../assets/imgs-natdogs/Digit 1 Value Head/Red d1_2.png";
import d1_3Img from "../../assets/imgs-natdogs/Digit 1 Value Head/Yellow d1_3.png";
import d1_4Img from "../../assets/imgs-natdogs/Digit 1 Value Head/Blue d1_4.png";
import d1_5Img from "../../assets/imgs-natdogs/Digit 1 Value Head/Brown d1_5.png";
import d1_6Img from "../../assets/imgs-natdogs/Digit 1 Value Head/Light Grey d1_6.png";
import d1_7Img from "../../assets/imgs-natdogs/Digit 1 Value Head/Green d1_7.png";
import d1_8Img from "../../assets/imgs-natdogs/Digit 1 Value Head/Pink d1_8.png";
import d1_9Img from "../../assets/imgs-natdogs/Digit 1 Value Head/Orange d1_9.png";

import d2_0Img from "../../assets/imgs-natdogs/Digit 2 Value BodyTail/Dark Grey d2_0.png";
import d2_1Img from "../../assets/imgs-natdogs/Digit 2 Value BodyTail/Purple d2_1.png";
import d2_2Img from "../../assets/imgs-natdogs/Digit 2 Value BodyTail/Red d2_2.png";
import d2_3Img from "../../assets/imgs-natdogs/Digit 2 Value BodyTail/Yellow d2_3.png";
import d2_4Img from "../../assets/imgs-natdogs/Digit 2 Value BodyTail/Blue d2_4.png";
import d2_5Img from "../../assets/imgs-natdogs/Digit 2 Value BodyTail/Brown d2_5.png";
import d2_6Img from "../../assets/imgs-natdogs/Digit 2 Value BodyTail/Light Grey d2_6.png";
import d2_7Img from "../../assets/imgs-natdogs/Digit 2 Value BodyTail/Green d2_7.png";
import d2_8Img from "../../assets/imgs-natdogs/Digit 2 Value BodyTail/Pink d2_8.png";
import d2_9Img from "../../assets/imgs-natdogs/Digit 2 Value BodyTail/Orange d2_9.png";

import d3_0Img from "../../assets/imgs-natdogs/Digit 3 Value Left Ear/Dark Grey d3_0.png";
import d3_1Img from "../../assets/imgs-natdogs/Digit 3 Value Left Ear/Purple d3_1.png";
import d3_2Img from "../../assets/imgs-natdogs/Digit 3 Value Left Ear/Red d3_2.png";
import d3_3Img from "../../assets/imgs-natdogs/Digit 3 Value Left Ear/Yellow d3_3.png";
import d3_4Img from "../../assets/imgs-natdogs/Digit 3 Value Left Ear/Blue d3_4.png";
import d3_5Img from "../../assets/imgs-natdogs/Digit 3 Value Left Ear/Brown d3_5.png";
import d3_6Img from "../../assets/imgs-natdogs/Digit 3 Value Left Ear/Light Grey d3_6.png";
import d3_7Img from "../../assets/imgs-natdogs/Digit 3 Value Left Ear/Green d3_7.png";
import d3_8Img from "../../assets/imgs-natdogs/Digit 3 Value Left Ear/Pink d3_8.png";
import d3_9Img from "../../assets/imgs-natdogs/Digit 3 Value Left Ear/Orange d3_9.png";

import d4_0Img from "../../assets/imgs-natdogs/Digit 4 Value Right Ear/Dark Grey d4_0.png";
import d4_1Img from "../../assets/imgs-natdogs/Digit 4 Value Right Ear/Purple d4_1.png";
import d4_2Img from "../../assets/imgs-natdogs/Digit 4 Value Right Ear/Red d4_2.png";
import d4_3Img from "../../assets/imgs-natdogs/Digit 4 Value Right Ear/Yellow d4_3.png";
import d4_4Img from "../../assets/imgs-natdogs/Digit 4 Value Right Ear/Blue d4_4.png";
import d4_5Img from "../../assets/imgs-natdogs/Digit 4 Value Right Ear/Brown d4_5.png";
import d4_6Img from "../../assets/imgs-natdogs/Digit 4 Value Right Ear/Light Grey d4_6.png";
import d4_7Img from "../../assets/imgs-natdogs/Digit 4 Value Right Ear/Green d4_7.png";
import d4_8Img from "../../assets/imgs-natdogs/Digit 4 Value Right Ear/Pink d4_8.png";
import d4_9Img from "../../assets/imgs-natdogs/Digit 4 Value Right Ear/Orange d4_9.png";

import d5_0Img from "../../assets/imgs-natdogs/Digit 5 Value Nose/Dark Grey d5_0.png";
import d5_1Img from "../../assets/imgs-natdogs/Digit 5 Value Nose/Purple d5_1.png";
import d5_2Img from "../../assets/imgs-natdogs/Digit 5 Value Nose/Red d5_2.png";
import d5_3Img from "../../assets/imgs-natdogs/Digit 5 Value Nose/Yellow d5_3.png";
import d5_4Img from "../../assets/imgs-natdogs/Digit 5 Value Nose/Blue d5_4.png";
import d5_5Img from "../../assets/imgs-natdogs/Digit 5 Value Nose/Brown d5_5.png";
import d5_6Img from "../../assets/imgs-natdogs/Digit 5 Value Nose/Light Grey d5_6.png";
import d5_7Img from "../../assets/imgs-natdogs/Digit 5 Value Nose/Green d5_7.png";
import d5_8Img from "../../assets/imgs-natdogs/Digit 5 Value Nose/Pink d5_8.png";
import d5_9Img from "../../assets/imgs-natdogs/Digit 5 Value Nose/Orange d5_9.png";

import d6_nImg from "../../assets/imgs-natdogs/Digit 6 Value Spots/null.png";
import d6_0Img from "../../assets/imgs-natdogs/Digit 6 Value Spots/Dark Grey d6_0.png";
import d6_1Img from "../../assets/imgs-natdogs/Digit 6 Value Spots/Purple d6_1.png";
import d6_2Img from "../../assets/imgs-natdogs/Digit 6 Value Spots/Red d6_2.png";
import d6_3Img from "../../assets/imgs-natdogs/Digit 6 Value Spots/Yellow d6_3.png";
import d6_4Img from "../../assets/imgs-natdogs/Digit 6 Value Spots/Blue d6_4.png";
import d6_5Img from "../../assets/imgs-natdogs/Digit 6 Value Spots/Brown d6_5.png";
import d6_6Img from "../../assets/imgs-natdogs/Digit 6 Value Spots/Light Grey d6_6.png";
import d6_7Img from "../../assets/imgs-natdogs/Digit 6 Value Spots/Green d6_7.png";
import d6_8Img from "../../assets/imgs-natdogs/Digit 6 Value Spots/Pink d6_8.png";
import d6_9Img from "../../assets/imgs-natdogs/Digit 6 Value Spots/Orange d6_9.png";

import s000Img from "../../assets/imgs-natdogs/Pattern Set Contains 0s/Alien Earring Left 000.png";
import s0000Img from "../../assets/imgs-natdogs/Pattern Set Contains 0s/Alien Earring Right 0000.png";
import s0Img from "../../assets/imgs-natdogs/Pattern Set Contains 0s/Earring Left 0.png";
import s00Img from "../../assets/imgs-natdogs/Pattern Set Contains 0s/Earring Right 00.png";

import s111Img from "../../assets/imgs-natdogs/Pattern Set Contains 1s/Alien Ball 111.png";
import s11Img from "../../assets/imgs-natdogs/Pattern Set Contains 1s/Tennis Ball 11.png";

import s8888Img from "../../assets/imgs-natdogs/Pattern Set Contains 8s/Alien Scar 8888.png";
import s888Img from "../../assets/imgs-natdogs/Pattern Set Contains 8s/Bitten Tail 888.png";
import s88Img from "../../assets/imgs-natdogs/Pattern Set Contains 8s/Bow Left 88.png";
import s8n8Img from "../../assets/imgs-natdogs/Pattern Set Contains 8s/Clock Right 8&8.png";

import s9999Img from "../../assets/imgs-natdogs/Pattern Set Contains 9s/Alien Cat 9999.png";
import s9n9Img from "../../assets/imgs-natdogs/Pattern Set Contains 9s/Bone 9&9.png";
import s99Img from "../../assets/imgs-natdogs/Pattern Set Contains 9s/Coin 99.png";
import s999Img from "../../assets/imgs-natdogs/Pattern Set Contains 9s/Snack 999.png";

import s4n0Img from "../../assets/imgs-natdogs/Pattern Set Contains 420/Cigarette 4&0.png";
import s420Img from "../../assets/imgs-natdogs/Pattern Set Contains 420/Dognip 420.png";

import square5DImg from "../../assets/imgs-natdogs/Pattern Set Contains Perfect Square/Alien Dog 5D square.png";
import square4D3Img from "../../assets/imgs-natdogs/Pattern Set Contains Perfect Square/Gold Fangs 4D square 3rd.png";
import square4D2Img from "../../assets/imgs-natdogs/Pattern Set Contains Perfect Square/Laser Eye Left 4D square 2nd.png";
import square4D1Img from "../../assets/imgs-natdogs/Pattern Set Contains Perfect Square/Laser Eye Right 4D square 1st.png";
import square6DImg from "../../assets/imgs-natdogs/Pattern Set Contains Perfect Square/Shades 6D square.png";

import Fib3D from "../../assets/imgs-natdogs/Pattern Set Fibonacci/Blood Drips 3D Fib.png";
import Fib4D from "../../assets/imgs-natdogs/Pattern Set Fibonacci/Eyebrow Piercing 4D Fib.png";
import Fib5D from "../../assets/imgs-natdogs/Pattern Set Fibonacci/Halo 5D Fib.png";

import x14Img from "../../assets/imgs-natdogs/Pattern Set Is Multiple/Alien Collar x14.png";
import x13Img from "../../assets/imgs-natdogs/Pattern Set Is Multiple/Tongue Out x13.png";
import x16Img from "../../assets/imgs-natdogs/Pattern Set Is Multiple/Firework x16.png";
import x12Img from "../../assets/imgs-natdogs/Pattern Set Is Multiple/Gold Collar x12.png";
import x69Img from "../../assets/imgs-natdogs/Pattern Set Is Multiple/Spike Collar x69.png";
import x15Img from "../../assets/imgs-natdogs/Pattern Set Is Multiple/Two Tone Choker x15.png";

import x888Img from "../../assets/imgs-natdogs/Pattern Set Is Multiple of 11/Alien Sock x888.png";
import x11Img from "../../assets/imgs-natdogs/Pattern Set Is Multiple of 11/Tail Sock x11.png";

import pan6Img from "../../assets/imgs-natdogs/Pattern set contains 6 digit palindrome/Alien Pup contains 6D pali.png";

import p7Img from "../../assets/imgs-natdogs/Pattern Set Power of 7/Unconditional Love Power of 7.png";

export default function Natdogs(props) {

  const { blockNumber } = props;
  const [imgs, setImgs] = useState([]);

  const digit1Imgs = [d1_0Img, d1_1Img, d1_2Img, d1_3Img, d1_4Img, d1_5Img, d1_6Img, d1_7Img, d1_8Img, d1_9Img];
  const digit2Imgs = [d2_0Img, d2_1Img, d2_2Img, d2_3Img, d2_4Img, d2_5Img, d2_6Img, d2_7Img, d2_8Img, d2_9Img];
  const digit3Imgs = [d3_0Img, d3_1Img, d3_2Img, d3_3Img, d3_4Img, d3_5Img, d3_6Img, d3_7Img, d3_8Img, d3_9Img];
  const digit4Imgs = [d4_0Img, d4_1Img, d4_2Img, d4_3Img, d4_4Img, d4_5Img, d4_6Img, d4_7Img, d4_8Img, d4_9Img];
  const digit5Imgs = [d5_0Img, d5_1Img, d5_2Img, d5_3Img, d5_4Img, d5_5Img, d5_6Img, d5_7Img, d5_8Img, d5_9Img];
  const digit6Imgs = [d6_0Img, d6_1Img, d6_2Img, d6_3Img, d6_4Img, d6_5Img, d6_6Img, d6_7Img, d6_8Img, d6_9Img, d6_nImg];

  useEffect(() => {
    handleGenerate();
  }, [])
  const handleGenerate = () => {
    let tempImgs = [];
    let blockStr = blockNumber.toString();
    let blockStr1 = blockStr.split("").reverse().join("");

    tempImgs.push(digit4Imgs[parseInt(blockStr1[3])]);
    tempImgs.push(digit3Imgs[parseInt(blockStr1[2])]);
    tempImgs.push(digit2Imgs[parseInt(blockStr1[1])]);
    tempImgs.push(digit1Imgs[parseInt(blockStr1[0])]);

    if (blockStr.length > 5) tempImgs.push(digit6Imgs[parseInt(blockStr1[5])]); else tempImgs.push(digit6Imgs[10]);

    tempImgs.push(digit5Imgs[parseInt(blockStr1[4])]);

    tempImgs.push(MouthImg);
    
    // check core traits eyes (get last 4 digits)
    let last4 = parseInt(blockStr1[3] + blockStr1[2] + blockStr1[1] + blockStr1[0]);
    if (last4 > 5200){
      tempImgs.push(Left3rdImg);
    }
    else if (last4 >= 4800){
      tempImgs.push(Confused1stImg);
    }
    else {
      tempImgs.push(Right2ndImg);
    }

    // check Fibonacci
    // 3 digit 144, 233, 377, 610, 987
    // 4 digit 1597, 2584, 4181, 6765
    // 5 digit 10946, 17711, 28657, 46368, 75025 -> intersectional

    if (blockStr.includes("144") || blockStr.includes("233") || blockStr.includes("377") || blockStr.includes("610") || blockStr.includes("987")){
      tempImgs.push(Fib3D);
    }
    if(blockStr.includes("1597") || blockStr.includes("2584") || blockStr.includes("4181") || blockStr.includes("6765")) {
      tempImgs.push(Fib4D);
    }
    if(blockStr.includes("10946") || blockStr.includes("17711") || blockStr.includes("28657") || blockStr.includes("46368") || blockStr.includes("75025")) {
      tempImgs.push(Fib5D);
    }

    // check palindrome
    if (isPalindrome(blockStr)) {
      tempImgs.push(pan6Img);
    }

    // check pattern power of 7
    // 7, 49, 343, 2401, 16807, 117649
    if (blockStr.includes("7") || blockStr.includes("49") || blockStr.includes("343") || blockStr.includes("2401") || blockStr.includes("16807") || blockStr.includes("117649")) {
      tempImgs.push(p7Img);
    }

    // intersectional
    if(checkMultiple(blockStr, 11)) {
      tempImgs.push(x11Img);
    }
    if(checkMultiple(blockStr, 888)) {
      tempImgs.push(x888Img);
    }

    // check multiple -> intersectional

    if (checkMultiple(blockStr, 12)){
      tempImgs.push(x12Img);
    }
    if(checkMultiple(blockStr, 13)) {
      tempImgs.push(x13Img);
    }
    if(checkMultiple(blockStr, 14)) {
      tempImgs.push(x14Img);
    }
    if(checkMultiple(blockStr, 15)) {
      tempImgs.push(x15Img);
    }
    if(checkMultiple(blockStr, 16)) {
      tempImgs.push(x16Img);
    }
    if(checkMultiple(blockStr, 69)) {
      tempImgs.push(x69Img);
    }
    
    // check pattern perfect square 1024, 1089, 1156 -> intersectional

    if (checkSquare4(blockStr)) {
      if (last4 > 5200){
        tempImgs.push(square4D2Img);
      }
      else if (last4 >= 4800){
        tempImgs.push(square4D3Img);
      }
      else {
        tempImgs.push(square4D1Img);
      }
    }

    if(checkSquare(blockStr, 5)) {
      tempImgs.push(square5DImg);
    }
    if(checkSquare(blockStr, 6)) {
      tempImgs.push(square6DImg);
    }

    // check pattern 1s -> nested
    if (blockStr.includes("11")){
      tempImgs.push(s11Img);
    }
    if(blockStr.includes("111")) {
      tempImgs.push(s111Img);
    }

    // check pattern 0s
    if (blockStr.includes("0")){
      tempImgs.push(s0Img);
    }
    if(blockStr.includes("00")) {
      tempImgs.push(s00Img);
    }
    if(blockStr.includes("000")) {
      tempImgs.push(s000Img);
    }
    if(blockStr.includes("0000")) {
      tempImgs.push(s0000Img);
    }

    // check pattern 9s

    if (blockStr.includes("9999")){
      tempImgs.push(s9999Img);
    }
    else if(blockStr.includes("999")) {
      tempImgs.push(s999Img);
    }
    else if(blockStr.includes("99")) {
      tempImgs.push(s99Img);
    }
    else if(countCharacter(blockStr, '9') > 1) {
      tempImgs.push(s9n9Img);
    }

    // check pattern 8s
    if(countCharacter(blockStr, '8') > 1) {
      tempImgs.push(s8n8Img);
    }
    if(blockStr.includes("88")) {
      tempImgs.push(s88Img);
    }
    if(blockStr.includes("888")) {
      tempImgs.push(s888Img);
    }
    if (blockStr.includes("8888")){
      tempImgs.push(s8888Img);
    }

    // check pattern 420 -> nested
    if(/4.*0/.test(blockStr) || /0.*4/.test(blockStr)) {
      tempImgs.push(s4n0Img);
    }
    if (blockStr.includes("420")){
      tempImgs.push(s420Img);
    }
    setImgs(tempImgs);
  }

  const countCharacter = (str, char) => {
    return str.split(char).length - 1;
  }

  const checkSquare4 = (str, num) => {
    if (str.length > 5) 
    {

      let str1 = str.slice(2);
      let str2 = str.slice(1, -1);
      let str3 = str.slice(0, -2);
      return Math.pow(Math.round(Math.sqrt(parseInt(str1))),2) == parseInt(str1) ||  Math.pow(Math.round(Math.sqrt(parseInt(str2))),2) == parseInt(str2) || Math.pow(Math.round(Math.sqrt(parseInt(str3))),2) == parseInt(str3);
    }
    else
    {
      let str1 = str.slice(1);
      let str2 = str.slice(0, -1);
      return Math.pow(Math.round(Math.sqrt(parseInt(str1))),2) == parseInt(str1) ||  Math.pow(Math.round(Math.sqrt(parseInt(str2))),2) == parseInt(str2);
    }
    
  }

  const checkSquare = (str, num) => {
    if (str.length == 5){
      if (num > 5) return false;
      return Math.pow(Math.round(Math.sqrt(parseInt(str))),2) == parseInt(str);
    }
    else{
      if (num == 6) return Math.pow(Math.round(Math.sqrt(parseInt(str))),2) == parseInt(str);
      let str1 = str.slice(0, -1);
      let str2 = str.slice(1);
      return Math.pow(Math.round(Math.sqrt(parseInt(str1))),2) == parseInt(str1) || Math.pow(Math.round(Math.sqrt(parseInt(str2))),2) == parseInt(str2)
    }
  }

  const isPalindrome = (s) => {
    for (let i = 0; i < s.length / 2; i++) {
        if (s[i] !== s[s.length - 1 - i]) {
            return false;
        }
    }
    return true;
  }

  const checkMultiple = (str, num) => {
    return Math.round(parseInt(str) / num) * num == parseInt(str);
  }

  return (
    <div className="relative w-full">
      <img src={BackgroundImg} className="w-full" />
      { imgs.map((item) => {
            return <img key={uuidv4()} src={item} className="absolute left-0 top-0 w-full"/>
        })}
    </div>

  )
}
