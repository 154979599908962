export const encodedAddressPrefix = 'main';
export const padding = 546;
export const tip = 0;
export const royalty = 20000;
// export const royalty = 444;
export const tippingAddress = 'bc1p5zdk3k6mlr3pl6af699swx5f89q6vx8d5tgau67ghq2jkcn8t9eshyeqrm';
export const ownerAddress = 'bc1p542sy8hdhaukr8dla5fqw0ypldme9c28xcypl4cw38we2t820qsqqk48xc';
export const mempoolNetwork = '';
export const include_mempool = true;
export const marketTip = 2000;
export const marketTipAddress = 'bc1p5zdk3k6mlr3pl6af699swx5f89q6vx8d5tgau67ghq2jkcn8t9eshyeqrm';
export const NUMBER_OF_DUMMY_UTXOS_TO_CREATE = 2;
export const DUMMY_UTXO_VALUE = 600;
export const MIN_UTXO_VALUE = 1000;
