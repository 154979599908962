import React from 'react'
import githubImg from '../assets/imgs/github.png';
import mainImg from '../assets/imgs/home-app.png';
export default function Wallet() {
  return (
    <div className="text-white mt-[65px] w-full max-w-[1500px] md:px-7 px-2 flex flex-row justify-center items-center">
      <div className="flex lg:flex-row flex-col gap-10 mt-[60px]">
        <div className="flex flex-col gap-5 max-w-[600px]">
          <div className="text-primary font-semibold text-[36px]">Tap Wallet</div>
          <div className="text-[32px]">Home for your bitcoin assets.</div>
          <div className="mt-3">Easy and safe to trade, inscribe, and manage assets with Tap Wallet, advancing Bitcoin technology.</div>
          <div className="flex flex-row gap-2 mt-10">
            <div className="flex flex-row rounded-md border-solid border-[1px] border-gray-500 px-5 py-1 gap-3 min-w-[200px] cursor-pointer items-center">
              <img src="https://next-cdn.unisat.io/_/139/img/icon/chrome.svg"/>
              <div className="flex flex-col">
                <span className="text-gray-500">Download</span>
                <span className="hover:text-primary-hover">Chrome Extension</span>
              </div>
            </div>
            <div className="flex flex-row rounded-md border-solid border-[1px] border-gray-500 px-5 py-1 gap-3 min-w-[200px] cursor-pointer items-center">
              <img src={githubImg} className="w-[30px] h-[30px]"/>
              <div className="flex flex-col">
                <span className="text-gray-500">Download</span>
                <span className = "hover:text-primary-hover">From Github</span>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[500px]">
          <img src={mainImg} alt=""/>
        </div>
      </div>
    </div>
  )
}
