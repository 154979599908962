import React, {useState, useEffect, useCallback} from 'react'
import { 
  Spinner,
  IconButton,
  Typography,
  Input,
  Progress
} from '@material-tailwind/react';
import { v4 as uuidv4 } from 'uuid';
const { io } = require('socket.io-client');
import { debounce } from 'lodash';
import { ArrowRightIcon, ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import TapIndexerDetail from './TapIndexerDetail';
import { formatAddress } from '../../util/format-data';

export default function TapIndexer() {
  const [ticker, setTicker] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [tokenTotal, setTokenTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tapDatas, setTapDatas] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [detailticker, setDetailTicker] = useState('');
  const [detailTotal, setDetailTotal] = useState(0);
  const [holders, setHolders] = useState(0);
  const [dec, setDec] = useState(0);

  useEffect(() => {
    fetchTokenData(ticker);
  }, [page])

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const doublePrev = () => {
    setPage(1);
  }

  const doubleNext = () => {
    setPage(total);
  }
  
  const handleKeyChange = (e) => {
    setTicker(e.target.value)
    debouncedSearch(e.target.value)
  }

  const debouncedSearch = useCallback(debounce( async (value) => {
    try {
      fetchTokenData(value);
    }
    catch (e) {
      console.log(e);
    }
  }, 300), []); 

  const fetchTokenData = (value) => {
    setLoading(true);
    setTapDatas([]);
    let tempTapDatas = [];
    let total = 0;

    const trac = io('https://tap-site.trac.network', {
      autoConnect: true,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionDelayMax: 500,
      randomizationFactor: 0
    });

    trac.connect();

    trac.on('response', async function(msg){
      if (typeof msg.call_id === 'object') {
        switch(msg.call_id.cmd) {
          case 'renderTokens_tokenList' :
            if (msg.call_id['single_ticker'] != '') {
              msg.result = 1;
            }
            total = msg.result; // total count of deployment
    
            getDeploymentsList(
              (page - 1) * limit, // page offset
              limit, // page limit
              {
                  cmd: 'renderTokens_getDeploymentsList',
                  deployments_length: msg.result,
                  single_ticker : msg.call_id.single_ticker
              }
            );
    
            break;
          
          case 'renderTokens_getDeploymentsList' :
            if (msg.result == null) {
              total = 0;
              setTokenTotal(0);
              setTotal(0);
              setTapDatas([]);
              setLoading(false);
            }
            else
            {
              if (msg.call_id['single_ticker'] != '') {
                msg.result = [msg.result];
              }
      
              const tokens = msg.result;
      
              for(let i = 0; i < tokens.length; i++) {
                getHoldersLength(
                  msg.result[i].tick,
                  {
                      cmd: 'renderTokens_mintListLength',
                      deployments_length: msg.call_id.deployments_length,
                      deployment : tokens[i],
                      index : i
                  }
                );
              }
            }
            break;
          
          case 'renderTokens_mintListLength' :
            getTickerMintListLength(
              msg.call_id.deployment.tick,
              {
                  cmd: 'renderTokens_getMintTokensLeft',
                  deployments_length: msg.call_id.deployments_length,
                  holders_length : msg.result,
                  deployment : msg.call_id.deployment,
                  index : msg.call_id.index
              }
            );
            break;
          
          case 'renderTokens_getMintTokensLeft' :
            getMintTokensLeft(
              msg.call_id.deployment.tick,
              {
                  cmd: 'renderTokens_end',
                  deployments_length: msg.call_id.deployments_length,
                  holders_length : msg.call_id.holders_length,
                  mints_length : msg.result,
                  deployment : msg.call_id.deployment,
                  index : msg.call_id.index
              }
            );
            break;
          
          case 'renderTokens_end' :
            let tokens_left = msg.result;
            let tokens_holders_length = msg.call_id.holders_length;
            let tokens_ticker = msg.call_id.deployment.tick;
            let tokens_deployment = msg.call_id.deployment;
            let tokens_index = msg.call_id.index;
            let minted = BigInt(tokens_deployment.max) - BigInt(tokens_left);
            let progress = ( ( Number(minted * 100n / BigInt(tokens_deployment.max)) / 100 ) * 100 ) + '%';

            tempTapDatas.push(
              {
                tokens_left: tokens_left,
                tokens_holders_length: tokens_holders_length,
                tokens_ticker: tokens_ticker,
                tokens_deployment: tokens_deployment,
                tokens_index: tokens_index,
                progress: progress
              }
            )
            
            if (tempTapDatas.length >= total || tempTapDatas.length >= limit) {
              tempTapDatas.sort(function(a, b) {
                return a.tokens_index - b.tokens_index;
              });
              console.log("----tempTapDatas-------", tempTapDatas);
              setTapDatas(tempTapDatas);
              setTokenTotal(total);
              setTotal(Math.ceil(total / limit));
              setLoading(false);
              console.log(tempTapDatas);
            }
            break;
        }
      }
    });

    const renderTokens = async (ticker = '') => {
      trac.emit('get',
      {
          func : 'deploymentsLength',
          args : [],
          call_id : { cmd : 'renderTokens_tokenList', single_ticker : ticker }
      });
    }
    
    const getDeploymentsList = async (offset = 0, max = 500, call_id = null) =>
    {
        if(call_id.single_ticker !== '')
        {
            trac.emit('get',
            {
                func : 'deployment',
                args : [call_id.single_ticker],
                call_id : call_id === null ? '' : call_id
            });
        }
        else
        {
            trac.emit('get',
            {
                func : 'deployments',
                args : [offset, max],
                call_id : call_id === null ? '' : call_id
            });
        }
    }
    
    const getHoldersLength = async (ticker, call_id = null) =>
    {
        trac.emit('get',
        {
            func : 'holdersLength',
            args : [ticker],
            call_id : call_id === null ? '' : call_id
        });
    }
    
    const getTickerMintListLength = async (ticker, call_id = null) =>
    {
        trac.emit('get',
        {
            func : 'tickerMintListLength',
            args : [ticker],
            call_id : call_id === null ? '' : call_id
        });
    }
    
    const getMintTokensLeft = async (ticker, call_id = null) =>
    {
        trac.emit('get',
        {
            func : 'mintTokensLeft',
            args : [ticker],
            call_id : call_id === null ? '' : call_id
        });
    }
    renderTokens(value);
  }

  const showDetailIndexer = (value, holders) => {
    setDetailTicker(value.tick);
    setDetailTotal(parseInt(value.max) / Math.pow(10, value.dec));
    setHolders(holders);
    setDec(value.dec);
    setShowDetail(true);
  }

  return (
    <div className="text-white w-full max-w-[1500px] flex flex-col justify-center items-center">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/> : <></>
      }
      {
       showDetail ? <TapIndexerDetail ticker = {detailticker} total = {Math.ceil(holders/10)} holders ={holders}  handleShowDetail = {setShowDetail} cnt = {detailTotal} dec={dec} setLoading = {setLoading}/> : 
      <>
        {
          <div className="flex flex-col gap-2 max-w-[1200px] w-full mt-5">
            { 
              <div className="mt-5 flex flex-row w-full justify-end">
                <div>
                  <Input type="text" color="white" label = "Search by ticker" className="w-[250px]" value = {ticker} onChange = {(e) => {handleKeyChange(e)}}/>
                </div>
              </div>
            }
            <div className="flex flex-row px-4 py-2">
              <div className = "w-[15%]">Tick</div>
              <div className = "md:w-[10%] w-[25%]">Holders</div>
              <div className = "md:w-[35%] w-[25%]">Progress</div>
              <div className = "w-[20%]">Number</div>
              <div className = "md:w-[15%] md:block hidden">DeploymentId</div>
              <div className = "w-[5%]">Cursed</div>
            </div>
            {
              tapDatas.map((data) => 
                <div className="flex flex-row gap-1 text-white bg-gray-900 p-4 rounded-lg cursor-pointer" onClick={() => showDetailIndexer(data.tokens_deployment, data.tokens_holders_length)}>
                  <div className = "w-[15%] text-primary font-bold hover:underline">
                    {data.tokens_deployment.tick}
                  </div>
                  <div className = "md:w-[10%] w-[25%]">{data.tokens_holders_length}</div>
                  <div className = "md:w-[35%] w-[25%] text-blue-600 flex flex-row items-center gap-2">
                    <div className="w-[120px] md:block hidden"><Progress  value={parseInt(data.progress.split('%')[0])} color="blue" size="sm" /></div>
                    <div>{data.progress}</div>
                  </div>
                  <div className = "w-[20%]">{data.tokens_deployment.num}</div>
                  <div className = "md:w-[15%] md:block hidden hover:text-primary">
                    <a href={`https://ordinals.com/inscription/${data.tokens_deployment.ins}`} target="_blank" rel="noopener noreferrer" >{formatAddress(data.tokens_deployment.ins)}
                    </a>
                  </div>
                  <div className = "w-[5%]">{data.tokens_deployment.crsd ? '-' : ''}</div>
                </div>
              )
            }
          </div> 
        }
        {
          total > 1 ? 
          <div className="flex flex-row items-center gap-8 justify-end mt-5">
            <div className="flex flex-row items-center gap-2">
              {/* <IconButton
                size="sm"
                variant="outlined"
                onClick={doublePrev}
                disabled={page === 1}
                className="bg-primary hover:bg-primary-hover"
              >
                <ChevronDoubleLeftIcon strokeWidth={2} className="h-4 w-4" />
              </IconButton> */}
              <IconButton
                size="sm"
                variant="outlined"
                onClick={prev}
                disabled={page === 1}
                className="bg-primary hover:bg-primary-hover"
              >
                <ChevronLeftIcon strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
            <Typography color="white" className="font-normal">
              Page <strong className="text-primary">{page}</strong> of{" "}
              <strong className="text-primary">{total}</strong>
            </Typography>
            <div className="flex flex-row items-center gap-2">
              <IconButton
                size="sm"
                variant="outlined"
                onClick={next}
                disabled={page === total}
                className="bg-primary hover:bg-primary-hover"
              >
                <ChevronRightIcon strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              {/* <IconButton
                size="sm"
                variant="outlined"
                onClick={doubleNext}
                disabled={page === total}
                className="bg-primary hover:bg-primary-hover"
              >
                <ChevronDoubleRightIcon strokeWidth={2} className="h-4 w-4" />
              </IconButton> */}
            </div>
          </div>
          :
          <></>
        }
      </>  
    }
    </div>
  )
}
