import React, {useEffect, useCallback, useState} from 'react'
import { getUnatTickers, getUnatAddress } from '../../util/api'
import { debounce } from 'lodash';
import { ArrowRightIcon, ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, FunnelIcon } from "@heroicons/react/24/outline";
import toast, { Toaster } from 'react-hot-toast';
import { 
  Spinner,
  IconButton,
  Typography,
  Input,
  Progress,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from '@material-tailwind/react';
import { v4 as uuidv4 } from 'uuid';
import { formatAddress } from '../../util/format-data';

export default function UnatIndexerDetail(props) {
  const { ticker, handleShowDetail, setLoading} = props;

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [unatDatas, setUnatDatas] = useState([]);
  const [cnt, setCnt] = useState(0);
  const [holders, setHolders] = useState(0);

  useEffect(() => {
    fetchTokenData(ticker, page);
  }, [page])

  const fetchTokenData = async (value, page) => {
    setLoading(true);
    let data = await getUnatTickers(value, limit, page);

    let tempUnatDatas = data.data;
    let tempAddresses = [];
    for(let tempUnatData of tempUnatDatas) {
      tempAddresses.push(tempUnatData.address);
    }

    let addressLists = await getUnatAddress(tempAddresses);

    for(let tempUnatData of tempUnatDatas) {
      tempUnatData.domain = "";
      for(let addressList of addressLists) {
        if (tempUnatData.address == addressList.address) tempUnatData.domain = addressList.domain;
      }
    }

    console.log(tempUnatDatas);
    setUnatDatas(tempUnatDatas);
    setHolders(data.totalHolders);
    setTotal(Math.ceil(data.totalHolders / limit));
    setCnt(data.total);
    setLoading(false);
  }

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const doublePrev = () => {
    setPage(1);
  }
  
  const doubleNext = () => {
    setPage(total);
  }

  const handleCopy = (value, mode) => {
    navigator.clipboard.writeText(value).then(
      () => {
        // Successfully copied to clipboard
        if (mode == 1)
          toast.success("Name copied!");
        else
          toast.success("Address copied!");
      },
      (err) => {
        // Failed to copy to clipboard
        console.error('Could not copy address: ', err);
      }
    );
  }

  return (
    <div className="mt-5 flex flex-col w-full gap-2 max-w-[1000px]">
      <div className="mt-3 font-bold text-primary text-[24px] flex flex-row gap-2 items-center">
        <ArrowLeftIcon strokeWidth={3} className="h-5 w-5 cursor-pointer" onClick={() => handleShowDetail(false)}/>
        <div >Dmt-{ticker} ({holders})</div>
      </div>
      <div className="flex flex-row px-4 py-2">
        <div className = "md:w-[40%] w-[40%]">Address</div>
        <div className = "md:w-[40%] w-[30%]">Percentage</div>
        <div className = "md:w-[20%] w-[30%]">Amount</div>
      </div>
      {
        unatDatas.map((data) => 
          <div 
            className="flex flex-row gap-1 text-white bg-gray-900 p-4 rounded-lg" 
            key={uuidv4()}
          >
            {
              data.domain != "" ? 
              <div className = "md:w-[40%] w-[40%] text-primary cursor-pointer" onClick={() => handleCopy(data.address, 2)}>{data.domain}</div>
              :
              <div className = "md:w-[40%] w-[40%] cursor-pointer" onClick={() => handleCopy(data.address, 2)}>{formatAddress(data.address)}</div>
            }
            <div className = "md:w-[40%] w-[30%] text-blue-600 flex flex-row items-center gap-2">
              <div className="w-[120px] md:block hidden"><Progress  value={Math.ceil(data.count * 100 /cnt)} color="blue" size="sm" /></div>
              <div>{Math.floor(data.count * 1000 /cnt) / 10}%</div>
            </div>
            <div className = "md:w-[20%] w-[30%]">{data.count}</div>
          </div>
        )
      }
      {
        total > 1 ? 
        <div className="flex flex-row items-center gap-8 justify-end mt-5">
          <div className="flex flex-row items-center gap-2">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={doublePrev}
              disabled={page === 1}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronDoubleLeftIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={prev}
              disabled={page === 1}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronLeftIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
          <Typography color="white" className="font-normal">
            Page <strong className="text-primary">{page}</strong> of{" "}
            <strong className="text-primary">{total}</strong>
          </Typography>
          <div className="flex flex-row items-center gap-2">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={next}
              disabled={page === total}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronRightIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={doubleNext}
              disabled={page === total}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronDoubleRightIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
        </div>
        :
        <></>
      }
       <Toaster 
        position="top-right"
      />
    </div>
  )
}
