
export const latestDomain = async (key, offset = 1, limit = 20) => {

  const url = `https://api.tapwallet.io/domain/latest?key=${key}&offset=${offset}&limit=${limit}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getAllDomains = async (key, offset = 1, limit = 20) => {

  const url = `https://api.tapwallet.io/domain/all?key=${key}&offset=${offset}&limit=${limit}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const registerPayment = async (key, address, inscriptionAddress = "") => {
  
  const url = 'https://api.tapwallet.io/payment/register';
  const data = {
    key: key,
    address: address,
    inscription_address: inscriptionAddress,
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const getUnatData = async (address, limit, offset, ticker, blk) => {
  const url = `https://api.tapwallet.io/unat?limit=${limit}&offset=${offset}&address=${address}&ticker=${ticker}&blk=${blk}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getUnatIndex = async (ticker = '', limit = 20, offset = 0, order = 1) => {
  const url = `https://api.tapwallet.io/unat/index?limit=${limit}&offset=${offset}&ticker=${ticker}&order=${order}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getUnatHolders = async (ticker = '', limit = 20, offset = 0, order = 1) => {
  const url = `https://api.tapwallet.io/unat/holders?limit=${limit}&offset=${offset}&ticker=${ticker}&order=${order}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getUnatTickers = async (ticker = '', limit = 20, offset = 0) => {
  const url = `https://api.tapwallet.io/unat/ticker?limit=${limit}&offset=${offset}&ticker=${ticker}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getUnatAddress = async (addressList) => {
  const url = 'https://api.tapwallet.io/unat/address';
  const data = {
    addressList: addressList
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}


export const getInscriptionIds = async (address) => {
  const url = `https://api.brc444.com/fetchOrdinals?address=${address}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const registerDomain = async (_address, domain, address) => {
  
  const url = 'https://api.tapwallet.io/domain/register';
  const data = {
    _address: _address,
    domain: domain,
    address: address
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

}

export const checkDomain = async (domain) => {
  
  const url = 'https://api.tapwallet.io/domain/check';
  const data = {
    domain: domain
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const lockDomain = async (domain) => {
  
  const url = 'https://api.tapwallet.io/domain/lock';
  const data = {
    domain: domain
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const unlockDomain = async (domain) => {
  
  const url = 'https://api.tapwallet.io/domain/unlock';
  const data = {
    domain: domain
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

// export const getUnat = async (inscriptionIds) => {
  
//   const url = 'https://api.tapwallet.io/unat';
//   const data = {
//     inscriptionIds: inscriptionIds
//   };

//   let response  = await fetch(url, {
//     method: 'POST', 
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify(data),
//   })

//   return await response.json();
// }

export const getPaymentHistory = async () => {
  const url = 'https://api.tapwallet.io/payment_history';

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const savePaymentHistory = async (value) => {
  const url = 'https://api.tapwallet.io/payment_history';
  const data = {
    value: value
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const calculateFee = (vins, vouts, recommendedFeeRate, includeChangeOutput = 0 ) => {
  if (typeof recommendedFeeRate !== "number" || recommendedFeeRate <= 0)
    throw new Error("Invalid fee rate.");

  const baseTxSize = 10;
  const inSize = 57.5;
  const outSize = 43;
  const txSize = baseTxSize + vins * inSize + vouts * outSize + includeChangeOutput * outSize;
  const fee = Math.round(txSize * recommendedFeeRate);
  return fee;
}

export const updateUnat = async (address) => {
  const url = 'https://api.tapwallet.io/unat/update';
  const data = {
    address: address,
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getPaymentUtxos = async (paymentAddress, fundingAddress, fundingAmount, paymentPubkey, feeRate) => {
  
  const url = 'https://api.brc444.com/getPaymentUtxos';
  const data = {
    paymentAddress: paymentAddress,
    fundingAddress: fundingAddress,
    fundingAmount: fundingAmount,
    paymentPubkey: paymentPubkey,
    feeRate: Math.round(feeRate * 1.3)
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getPaymentTx = async (psbtBase64) => {
  
  const url = 'https://api.brc444.com/getPaymentTx';
  const data = {
    psbtBase64: psbtBase64
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}


export const getUnisatBrc20 = async(address, cursor) => {
  const url = 'https://wallet-api.unisat.io/v5/brc20/list';
  const params = new URLSearchParams({
    address: address,
    cursor: cursor,
    size: 100
  });

  const headers = new Headers();
  headers.append('X-Client', 'UniSat Wallet');
  headers.append('X-Version', "1.2.5");
  headers.append('x-address', address);
  headers.append('x-flag', 0 + '');
  headers.append('x-channel', "store");
  headers.append('x-udid', 'UkVxslRzcRkF');

  try {
    const response = await fetch(`${url}?${params}`, {
      method: 'GET', // GET is the default method and can be omitted.
      headers,
      mode: 'cors', cache: 'default'
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.data;
  } catch (e) {
    console.error('Fetch error:', e.message);
  }
}

export const getOrdinals = async(address, cursor, size = 100) => {
  const url = 'https://wallet-api.unisat.io/v5/ordinals/inscriptions';
  const params = new URLSearchParams({
    address: address,
    cursor: cursor,
    size: size
  });

  const headers = new Headers();
  headers.append('X-Client', 'UniSat Wallet');
  headers.append('X-Version', "1.2.5");
  headers.append('x-address', address);
  headers.append('x-flag', 0 + '');
  headers.append('x-channel', "store");
  headers.append('x-udid', 'UkVxslRzcRkF');

  try {
    const response = await fetch(`${url}?${params}`, {
      method: 'GET', // GET is the default method and can be omitted.
      headers,
      mode: 'cors', cache: 'default'
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data.data;
  } catch (e) {
    console.error('Fetch error:', e.message);
  }
}

export const getInscriptionContent =  async (inscriptionId) => {
  const url = new URL(`https://ordinals.com/content/${inscriptionId}`);
  let data = ""
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    data = await response.text();
  } catch (error) {
    console.error('Error fetching data:', error);
  }
  finally {
    return data;
  }
}


export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

