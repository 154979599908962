import React, {useState, useEffect, useCallback} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Brc20 from '../components/Explorer/Brc20'
import Dmt from '../components/Explorer/Dmt'
import Tap from '../components/Explorer/Tap'
import Unat from '../components/Explorer/Unat'
import Inscription from '../components/Explorer/Inscription';
import { formatAddress } from '../util/format-data';
import toast, { Toaster } from 'react-hot-toast';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { debounce } from 'lodash';
import { latestDomain } from '../util/api';
import { 
  Input,
  Popover,
  PopoverHandler,
  PopoverContent,
  Button
} from '@material-tailwind/react';

import {
  Routes,
  Route,
  useNavigate,
  Link
} from "react-router-dom";
import { updateKey } from '../store/action';

export default function Explorer() {
  const wallet = useSelector(state => state.wallet);
  const [keyAddress, setKeyAddress] = useState(wallet.nostrOrdinalsAddress);

  const navigate = useNavigate();
  const [tab, setTab] = useState(0) // 0: brc20, 1: tap, 2: dmt, 3: unat

  useEffect(() => {
    setKeyAddress(wallet.nostrOrdinalsAddress);
  }, [wallet.nostrOrdinalsAddress])
  
  const handleCopy = (value) => {
    navigator.clipboard.writeText(value).then(
      () => {
        // Successfully copied to clipboard
        toast.success('Address copied to clipboard!')
      },
      (err) => {
        // Failed to copy to clipboard
        console.error('Could not copy address: ', err);
      }
    );
  }

  const handleMint = () => {
    navigate('/register');
  }
  return (
    <div className="text-white mt-[65px] w-full max-w-[1500px] md:px-7 px-2 flex flex-col justify-center items-center mb-10">
      <div className="mt-8 w-full">
        {
          keyAddress == "" ? <></> :
          <div className = "flex flex-row items-center gap-2">
            <div className="text-[32px] font-semibold">{formatAddress(keyAddress)}</div>
            <div className="flex flex-row items-center bg-primary px-2 py-1 rounded-md ml-3 cursor-pointer" onClick = {handleMint}>Register</div>
            <div className="flex flex-row items-center bg-primary px-2 py-1 rounded-md cursor-pointer" onClick={() => {handleCopy(keyAddress)}}>
              <DocumentDuplicateIcon className="h-5 w-5 cursor-pointer" />
              <span>Copy</span>
            </div>
          </div>
        }
      </div>
      <div className="flex flex-row gap-8 w-full mt-5">
        <div className={`text-[20px] ${tab == 0 ? 'text-primary border-b-[2px] border-b-primary border-b-solid' : ''} hover:text-primary pb-1 cursor-pointer`} onClick={() => setTab(0)}>ALL</div>
        <div className={`text-[20px] ${tab == 1 ? 'text-primary border-b-[2px] border-b-primary border-b-solid' : ''} hover:text-primary pb-1 cursor-pointer`} onClick={() => setTab(1)}>BRC-20</div>
        <div className={`text-[20px] ${tab == 2 ? 'text-primary border-b-[2px] border-b-primary border-b-solid' : ''} hover:text-primary pb-1 cursor-pointer`} onClick={() => setTab(2)}>TAP</div>
        <div className={`text-[20px] ${tab == 3 ? 'text-primary border-b-[2px] border-b-primary border-b-solid' : ''} hover:text-primary pb-1 cursor-pointer`} onClick={() => setTab(3)}>DMT</div>
        <div className={`text-[20px] ${tab == 4 ? 'text-primary border-b-[2px] border-b-primary border-b-solid' : ''} hover:text-primary pb-1 cursor-pointer`} onClick={() => setTab(4)}>UNAT</div>
      </div>
      {
        tab == 0 ? <Inscription keyAddress= {keyAddress} /> : <></>
      }
      {
        tab == 1 ? <Brc20 keyAddress= {keyAddress} /> : <></>
      }
      {
        tab == 2 ? <Tap keyAddress= {keyAddress} /> : <></>
      }
      {
        tab == 3 ? <Dmt keyAddress= {keyAddress} /> : <></>
      }
      {
        tab == 4 ? <Unat keyAddress= {keyAddress} /> : <></>
      }
      <Toaster 
        position="top-right"
      />
    </div>
  )
}
