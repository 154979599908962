import React, {useEffect, useCallback, useState} from 'react'
import { getUnatTickers, getUnatAddress } from '../../util/api'
import { debounce } from 'lodash';
import { ArrowRightIcon, ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, FunnelIcon } from "@heroicons/react/24/outline";
import toast, { Toaster } from 'react-hot-toast';
import { 
  Spinner,
  IconButton,
  Typography,
  Input,
  Progress,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from '@material-tailwind/react';
const { io } = require('socket.io-client');
import { v4 as uuidv4 } from 'uuid';
import { formatAddress } from '../../util/format-data';

export default function DmtIndexerDetail(props) {
  const { ticker, total, holders,handleShowDetail, setLoading} = props;

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [tapDatas, setTapDatas] = useState([]);

  useEffect(() => {
    fetchTokenData(ticker, page);
  }, [page])

  const fetchTokenData = async (value, page) => {
    setLoading(true);

    const trac = io('https://tap-site.trac.network', {
      autoConnect: true,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionDelayMax: 500,
      randomizationFactor: 0
    });

    trac.on('response', async function(msg){
      
      let tempTapDatas = msg.result;
      console.log(msg.result);
      let tempAddresses = [];
      for(let tempTapData of tempTapDatas) {
        tempAddresses.push(tempTapData.address);
      }

      let addressLists = await getUnatAddress(tempAddresses);

      for(let tempTapData of tempTapDatas) {
        tempTapData.domain = "";
        for(let addressList of addressLists) {
          if (tempTapData.address == addressList.address) tempTapData.domain = addressList.domain;
        }
      }

      console.log(tempTapDatas);
      setTapDatas(tempTapDatas);
      setLoading(false);
    });

    const getHolderLists = async (ticker, offset, max) => {
      trac.emit('get',
      {
          func : 'holders',
          args : [ticker, offset, max],
          call_id : {index: 1}
      });
    }

    getHolderLists("dmt-" +ticker, (page - 1) * limit, limit);
    console.log(value);    
  }

  const next = () => {
    if (page === total) return;
    setPage(page + 1);
  };
 
  const prev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const doublePrev = () => {
    setPage(1);
  }
  
  const doubleNext = () => {
    setPage(total);
  }

  const handleCopy = (value, mode) => {
    navigator.clipboard.writeText(value).then(
      () => {
        // Successfully copied to clipboard
        if (mode == 1)
          toast.success("Name copied!");
        else
          toast.success("Address copied!");
      },
      (err) => {
        // Failed to copy to clipboard
        console.error('Could not copy address: ', err);
      }
    );
  }

  return (
    <div className="mt-5 flex flex-col w-full gap-2 max-w-[1000px]">
      <div className="mt-3 font-bold text-primary text-[24px] flex flex-row gap-2 items-center">
        <ArrowLeftIcon strokeWidth={3} className="h-5 w-5 cursor-pointer" onClick={() => handleShowDetail(false)}/>
        <div >Dmt-{ticker} ({holders})</div>
      </div>
      <div className="flex flex-row px-4 py-2">
        <div className = "md:w-[50%]">Address</div>
        <div className = "md:w-[50%]">Amount</div>
      </div>
      {
        tapDatas.map((data) => 
          <div 
            className="flex flex-row gap-1 text-white bg-gray-900 p-4 rounded-lg" 
            key={uuidv4()}
          >
            {
              data.domain != "" ? 
              <div className = "md:w-[50%] w-[50%] text-primary cursor-pointer" onClick={() => handleCopy(data.address, 2)}>{data.domain}</div>
              :
              <div className = "md:w-[50%] w-[50%] cursor-pointer" onClick={() => handleCopy(data.address, 2)}>{formatAddress(data.address)}</div>
            }
            <div className = "md:w-[20%] w-[30%]">{parseInt(data.balance)}</div>
            
          </div>
        )
      }
      {
        total > 1 ? 
        <div className="flex flex-row items-center gap-8 justify-end mt-5">
          <div className="flex flex-row items-center gap-2">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={doublePrev}
              disabled={page === 1}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronDoubleLeftIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={prev}
              disabled={page === 1}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronLeftIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
          <Typography color="white" className="font-normal">
            Page <strong className="text-primary">{page}</strong> of{" "}
            <strong className="text-primary">{total}</strong>
          </Typography>
          <div className="flex flex-row items-center gap-2">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={next}
              disabled={page === total}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronRightIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={doubleNext}
              disabled={page === total}
              className="bg-primary hover:bg-primary-hover"
            >
              <ChevronDoubleRightIcon strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
        </div>
        :
        <></>
      }
       <Toaster 
        position="top-right"
      />
    </div>
  )
}
