import React, {useState, useEffect, useCallback} from 'react'
import { 
  Input,
  Popover,
  PopoverHandler,
  PopoverContent,
  Button
} from '@material-tailwind/react';
import { BellAlertIcon, QueueListIcon } from '@heroicons/react/24/outline';
import { getFeeRate } from '../util/inscribe-util';
import { RiBikeLine } from "react-icons/ri";
import { MdOutlineFlight } from "react-icons/md";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { debounce } from 'lodash';
import Connect from './Connect';
import logo from '../assets/imgs/logo.png';
import { latestDomain } from '../util/api';
import { useSelector, useDispatch } from 'react-redux';
import { updateKey } from '../store/action';
import {
  useNavigate,
} from "react-router-dom";

export default function Header(props) {
  const wallet = useSelector(state => state.wallet);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {tab, handle} = props;
  const [key, setKey] = useState("");
  const [feeRates, setFeeRates] = useState({});

  useEffect(() => {
    let intervalId;

    const updateFees = async () => {
      try {
        let response = await getFeeRate();
        setFeeRates(response);
      }
      catch (e) {
        console.log(e);
      }
    }
    updateFees();
    intervalId = setInterval(updateFees, 20 * 1000);
    return () => {
      clearInterval(intervalId);
    }
  }, [])

  const debouncedSearch = useCallback(debounce( async (value) => {
    let result = await latestDomain(value);
    let domain = "";
    let address = "";
    
    if (result.total == 0) 
      address = value;
    else
    {
      domain = value;
      address = result.data[0].address;
    }

    let keyData = {address: address, domain: domain};
    console.log(keyData);
    dispatch(updateKey(keyData));
    navigate('/explorer')

  }, 300), []); 

  const handleKeyChange = (e) => {
    setKey(e.target.value)
    debouncedSearch(e.target.value)
  }

  const logoClick = () => {
    navigate('/')
  }

  return (
    <div className="flex flex-row justify-center h-[65px] fixed top-0 z-50 w-full border-b-[1px] border-b-[#24242a] md:px-7 px-2 text-white bg-[#0b0b0d]">
      <div className=" flex flex-row justify-between gap-7 items-center w-full">
        <div className="flex flex-row gap-5 items-center">
          <div className="flex flex-row items-center">
            <img src = {logo} className="w-[40px] h-[40px] cursor-pointer" onClick={logoClick}/>
            <div className="md:flex hidden font-bold text-xl">Tap Explorer</div>
          </div>
          <div className="lg:flex hidden flex-row ">
            <Input type="text" color="white" label = "Input Name or Address" className="w-full min-w-[400px]" value={key} onChange = {handleKeyChange}/>
          </div>
          <div className="2xl:flex hidden flex-row items-center gap-5">
            <div className={`${tab == 0 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-lg`} onClick={() => {handle(0)}}>Wallet</div>
            <div className={`${tab == 1 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-lg`} onClick={() => {handle(1)}}>Name Registration</div>
            <div className={`${tab == 2 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-lg`} onClick={() => {handle(2)}}>Explorer</div>
            {
              wallet.nostrPaymentAddress != "" ? 
              <div className={`${tab == 3 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-lg`} onClick={() => {handle(3)}}>My Profile</div>
              : <></>
            }
            <div className={`${tab == 4 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-lg`} onClick={() => {handle(4)}}>Index</div>
          </div>
        </div>
        <div className = "flex flex-row gap-3 h-[44px]">
          <a href="https://twitter.com/thetapwallet" target="_blank">
            <img src="https://mscribe-webapp.s3.amazonaws.com/twitter.png" alt="Twitter" className="cursor-pointer w-[45px] mt-1 p-2 rounded-lg border-solid border-primary border-[2px]" />
          </a>
          <div className="sm:block hidden h-full">
            <Popover placement="bottom-end">
              <PopoverHandler>
                <div className="flex flex-row items-center gap-2 rounded-full cursor-pointer bg-primary hover:bg-primary-hover px-4 py-1 h-full">
                  <BellAlertIcon strokeWidth={2} className="h-5 w-5" />
                  <span>{feeRates.Normal}</span>
                </div>
              </PopoverHandler>
              <PopoverContent className="bg-black border-black">
                <div className="flex flex-col gap-3">
                  <div className="flex flex-row gap-2 itmes-center">
                    <RiBikeLine className="text-white text-lg"/>
                    <span className="text-white font-semibold">Low: {feeRates.Slow}&nbsp;sats/vB</span>
                  </div>
                  <div className="flex flex-row gap-2 itmes-center">
                    <MdOutlineFlight className="text-white text-lg"/>
                    <span className="text-white font-semibold">Medium: {feeRates.Normal}&nbsp;sats/vB</span>
                  </div>
                  <div className="flex flex-row gap-2 itmes-center">
                    <BsFillLightningChargeFill className="text-white text-lg"/>
                    <span className="text-white font-semibold">High: {feeRates.Fast}&nbsp;sats/vB</span>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </div>
          <Connect />
          <div className="2xl:hidden flex">
            <Popover placement="bottom-end">
              <PopoverHandler>
                <div className="flex flex-row items-center gap-2 rounded-md cursor-pointer bg-primary hover:bg-primary-hover px-[10px] py-2">
                  <QueueListIcon strokeWidth={2} className="h-5 w-5" />
                </div>
              </PopoverHandler>
              <PopoverContent className="bg-black border-black">
                <div className="flex flex-col gap-4 px-3">
                  <div className={`${tab == 0 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-lg`} onClick={() => {handle(0)}}>Wallet</div>
                  <div className={`${tab == 1 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-lg`} onClick={() => {handle(1)}}>Name Registration</div>
                  <div className={`${tab == 2 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-lg`} onClick={() => {handle(2)}}>Explorer</div>
                  {
                    wallet.nostrPaymentAddress != "" ? 
                    <div className={`${tab == 3 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-lg`} onClick={() => {handle(3)}}>My Profile</div>
                    : <></>
                  }
                  <div className={`${tab == 4 ? 'text-primary' : 'text-white'} font-semibold cursor-pointer hover:text-primary text-lg`} onClick={() => {handle(4)}}>Index</div>
                </div>
              </PopoverContent>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  )
}
