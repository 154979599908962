import React, {useState, useEffect} from 'react'
import TokenCard from '../TokenCard'
import { getUnisatBrc20 } from '../../util/api'
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from '@material-tailwind/react';
import { v4 as uuidv4 } from 'uuid';

export default function Brc20(props) {
  const { keyAddress } = props;

  const [brc20Data, setBrc20Data] =  useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchAllBrc20();
      }
      catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, [keyAddress])

  const fetchAllBrc20 = async() => {
    setLoading(true);
    try{
      let data = await getUnisatBrc20(keyAddress, 0);
      let total = data.total;
      let dataList = data.list;
      let tempBrc20Data = [];
  
      tempBrc20Data = handleDataList(dataList, tempBrc20Data);
  
      for(let i = 2; i<= Math.ceil(total / 100); i++) {
        let data = await getUnisatBrc20(keyAddress, (i-1) * 100);
        let dataList = data.list;
        tempBrc20Data = handleDataList(dataList, tempBrc20Data);
      }
      setBrc20Data(tempBrc20Data);
    }
    catch(e) {
      setLoading(false);
    }
    setLoading(false);
  }

  const handleDataList = (dataList, tempBrc20Data) => {
    for(let data of dataList) {
      tempBrc20Data.push(data);
    }
    return tempBrc20Data;
  }

  return (
    <div className="flex flex-col w-full">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/> : <></>
      }
      <div className="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-3 w-full mt-5">
        {
          brc20Data.length > 0 ? 
            brc20Data.map((data) => {
              return <TokenCard key={uuidv4()} tick = {data.ticker} transferable = {data.transferableBalance} available = {data.availableBalance} balance = {data.overallBalance}/>
            }) : <>No BRC-20 tokens.</>
        }
      </div>
    </div>
  )
}
