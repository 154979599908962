import React from 'react'
import { useNavigate } from 'react-router-dom';
import logo from '../assets/imgs/logo.png';

export default function Footer(props) {
  const navigate = useNavigate();

  return (
    <div className="w-full relative mt-[100px]"> 
      <img src="https://www.brc-20.io/images/footer-bg-shape.svg" className="w-full h-full"/>
      <div className="flex flex-col items-center gap-5 absolute top-8 w-full">
          <a href="https://twitter.com/thetapwallet" target="_blank">
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" class="h-[30px] w-[30px] text-primary" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M496 109.5a201.8 201.8 0 01-56.55 15.3 97.51 97.51 0 0043.33-53.6 197.74 197.74 0 01-62.56 23.5A99.14 99.14 0 00348.31 64c-54.42 0-98.46 43.4-98.46 96.9a93.21 93.21 0 002.54 22.1 280.7 280.7 0 01-203-101.3A95.69 95.69 0 0036 130.4c0 33.6 17.53 63.3 44 80.7A97.5 97.5 0 0135.22 199v1.2c0 47 34 86.1 79 95a100.76 100.76 0 01-25.94 3.4 94.38 94.38 0 01-18.51-1.8c12.51 38.5 48.92 66.5 92.05 67.3A199.59 199.59 0 0139.5 405.6a203 203 0 01-23.5-1.4A278.68 278.68 0 00166.74 448c181.36 0 280.44-147.7 280.44-275.8 0-4.2-.11-8.4-.31-12.5A198.48 198.48 0 00496 109.5z"></path></svg>
          </a>
        <div className="flex flex-row gap-8 text-white">
          <div className="cursor-pointer hover:text-primary" onClick = {() => {navigate("/privacy-policy")}}>Privacy Policy</div>
          <div className="cursor-pointer hover:text-primary" onClick = {() => {navigate("/terms-of-service")}}>Terms of Service</div>
        </div>
      </div>
    </div>
  )
}
