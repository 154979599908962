import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { emptyBalance, updateBalance } from '../../store/action';
import { Spinner } from '@material-tailwind/react';
const { io } = require('socket.io-client');
// import { trac } from '../../util/tap-util';
import { v4 as uuidv4 } from 'uuid';
import TokenCard from '../TokenCard';

export default function Dmt(props) {
  const { keyAddress } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const tap = useSelector(state => state.tap);
  const [tapDatas, setTapDatas] = useState([]);


  useEffect(() => {
    fetchTokenData();
  }, [keyAddress])

  const fetchTokenData = () => {
    setLoading(true);
    setTapDatas([]);
    let tempTapDatas = [];
    let total = 0;

    const trac = io('https://tap-site.trac.network', {
      autoConnect: true,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionDelayMax: 500,
      randomizationFactor: 0
    });

    trac.connect();

    trac.on('response', async function (msg) {
      if (typeof msg.call_id === 'object') {
        if (msg.call_id.cmd == 'renderBalances_accountTokens') {

          console.log("-----------------------------------I am here!!");
          getAccountTokens(msg.call_id.address, 0, 500, {
            cmd: 'renderBalances_getAccountTokens',
            address: msg.call_id.address
          });
        } else if (msg.call_id.cmd == 'renderBalances_getAccountTokens') {
          total = msg.result.length;
          if (total == 0) setLoading(false);
          for (let i = 0; i < msg.result.length; i++) {
            getBalance(msg.call_id.address, msg.result[i], {
              cmd: 'renderBalances_getDeployment',
              address: msg.call_id.address,
              ticker: msg.result[i]
            });
          }
        } else if (msg.call_id.cmd == 'renderBalances_getDeployment') {
          getDeployment(msg.call_id.ticker, {
            cmd: 'renderBalances_getTransferable',
            address: msg.call_id.address,
            ticker: msg.call_id.ticker,
            balance: msg.result
          });
        } else if (msg.call_id.cmd == 'renderBalances_getTransferable') {
          getTransferable(msg.call_id.address, msg.call_id.ticker, {
            cmd: 'renderBalances',
            address: msg.call_id.address,
            ticker: msg.call_id.ticker,
            balance: msg.call_id.balance,
            deployment: msg.result
          });
        } else if (msg.call_id.cmd == 'renderBalances') {
          const transferable = msg.result == null ? BigInt(0) : BigInt(msg.result);
          const balance = BigInt(msg.call_id.balance);
          const available = balance - transferable;

          console.log(
            'address',
            msg.call_id.address,
            'ticker',
            msg.call_id.ticker,
            'transferable',
            formatNumberString(transferable.toString(), msg.call_id.deployment.dec),
            'available',
            formatNumberString(available.toString(), msg.call_id.deployment.dec),
            'balance',
            formatNumberString(balance.toString(), msg.call_id.deployment.dec)
          );

          dispatch(
            updateBalance({
              ticker: msg.call_id.ticker,
              transferableBalance: formatNumberString(transferable.toString(), msg.call_id.deployment.dec),
              availableBalance: formatNumberString(available.toString(), msg.call_id.deployment.dec),
              overallBalance: formatNumberString(balance.toString(), msg.call_id.deployment.dec),
              availableBalanceSafe: formatNumberString(available.toString(), msg.call_id.deployment.dec),
              availableBalanceUnSafe: '0'
            })
          );

          tempTapDatas.push({
            ticker: msg.call_id.ticker,
            transferableBalance: formatNumberString(transferable.toString(), msg.call_id.deployment.dec),
            availableBalance: formatNumberString(available.toString(), msg.call_id.deployment.dec),
            overallBalance: formatNumberString(balance.toString(), msg.call_id.deployment.dec),
            availableBalanceSafe: formatNumberString(available.toString(), msg.call_id.deployment.dec),
            availableBalanceUnSafe: '0'
          });
          if (tempTapDatas.length == total)
          {
            let resData = [];
            for(let tempTapData of tempTapDatas) {
              if  (tempTapData.ticker.includes('dmt-')) resData.push(tempTapData);
            }
            setTapDatas(resData);
            setLoading(false);
          }  
        }
      }
    });

    const renderBalances = async (address) => {
      dispatch(emptyBalance({}));
      tempTapDatas = [];
  
      trac.emit('get', {
        func: 'accountTokensLength',
        args: [address],
        call_id: { cmd: 'renderBalances_accountTokens', address: address }
      });
    };

    const getAccountTokens = async (address, offset = 0, max = 500, call_id) => {
      trac.emit('get', {
        func: 'accountTokens',
        args: [address, offset, max],
        call_id: call_id === null ? '' : call_id
      });
    };

    const getBalance = async (address, ticker, call_id) => {
      trac.emit('get', {
        func: 'balance',
        args: [address, ticker],
        call_id: call_id === null ? '' : call_id
      });
    };

    const getTransferable = async (address, ticker, call_id) => {
      trac.emit('get', {
        func: 'transferable',
        args: [address, ticker],
        call_id: call_id === null ? '' : call_id
      });
    };

    const getDeployment = async (ticker, call_id) => {
      trac.emit('get', {
        func: 'deployment',
        args: [ticker],
        call_id: call_id === null ? '' : call_id
      });
    };

    const formatNumberString = (string, decimals) => {
      const pos = string.length - decimals;
      if (decimals == 0) {
        // nothing
      } else if (pos > 0) {
        //string = string.substring(0, pos) + '.' + string.substring(pos, string.length);
        string = string.substring(0, pos);
      } else {
        string = '0'; //'0.' + '0'.repeat(decimals - string.length) + string;
      }

      return string;
    };
    renderBalances(keyAddress);
  }

  return (
    <div className="flex flex-col w-full">
      {
        loading ? <Spinner className="h-16 w-16 text-gray-900/50 fixed top-[50%] left-[50%]" color="pink"/> : <></>
      }
      <div className="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-3 w-full mt-5">
        {
          tapDatas.length > 0 ? 
            tapDatas.map((data) => 
              data.ticker.includes('dmt-') ? 
              <TokenCard key={uuidv4()} tick = {data.ticker} transferable = {data.transferableBalance} available = {data.availableBalance} balance = {data.overallBalance}/> : <></>
            ) : <>No DMT tokens.</>
        }
      </div>
    </div>
  )
}
